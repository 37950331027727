
/**
 * Keys of all generated SVG sprite symbols.
 */
export type NuxtSvgSpriteSymbol =
  | 'arrow'
  | 'arrow_mobile'
  | 'bullet'
  | 'bullet_mobile'
  | 'check'
  | 'check-bold'
  | 'check-bold-white'
  | 'chevron-down'
  | 'chevron-down_mobile'
  | 'chevron-left'
  | 'chevron-left-white'
  | 'chevron-left-white-bold'
  | 'chevron-left-white-bold_mobile'
  | 'chevron-left-white_mobile'
  | 'chevron-left_mobile'
  | 'chevron-right'
  | 'chevron-right-white'
  | 'chevron-right-white-bold'
  | 'chevron-right-white-bold_mobile'
  | 'chevron-right-white_mobile'
  | 'chevron-right_mobile'
  | 'chevron-up'
  | 'chevron-up_mobile'
  | 'cross'
  | 'cross_mobile'
  | 'dot'
  | 'download'
  | 'download_mobile'
  | 'facebook'
  | 'heart'
  | 'info'
  | 'instagram'
  | 'linkedin'
  | 'mail'
  | 'mail_mobile'
  | 'menu'
  | 'menu_mobile'
  | 'minus'
  | 'minus_mobile'
  | 'pin'
  | 'pin_mobile'
  | 'play'
  | 'plus'
  | 'plus_mobile'
  | 'search'
  | 'search_mobile'
  | 'tiktok'
  | 'twitter'
  | 'youtube'

export const SPRITE_PATH = '/_nuxt/sprite.70aWa83KqC.svg'
export const SYMBOLS = ["arrow","arrow_mobile","bullet","bullet_mobile","check","check-bold","check-bold-white","chevron-down","chevron-down_mobile","chevron-left","chevron-left-white","chevron-left-white-bold","chevron-left-white-bold_mobile","chevron-left-white_mobile","chevron-left_mobile","chevron-right","chevron-right-white","chevron-right-white-bold","chevron-right-white-bold_mobile","chevron-right-white_mobile","chevron-right_mobile","chevron-up","chevron-up_mobile","cross","cross_mobile","dot","download","download_mobile","facebook","heart","info","instagram","linkedin","mail","mail_mobile","menu","menu_mobile","minus","minus_mobile","pin","pin_mobile","play","plus","plus_mobile","search","search_mobile","tiktok","twitter","youtube"]
