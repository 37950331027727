import provideState_MaX9fQr3GV from "/var/www/prod.exped.com/releases/202409301155/frontend/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.js";
import revive_payload_client_4sVQNw7RlN from "/var/www/prod.exped.com/releases/202409301155/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/prod.exped.com/releases/202409301155/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/prod.exped.com/releases/202409301155/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/prod.exped.com/releases/202409301155/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/prod.exped.com/releases/202409301155/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/prod.exped.com/releases/202409301155/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import blokkliEditable_kzWzvLoIBF from "/var/www/prod.exped.com/releases/202409301155/frontend/node_modules/blokkli-beta/dist/runtime/plugins/blokkliEditable.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/prod.exped.com/releases/202409301155/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import language_KdSMap7Fzd from "/var/www/prod.exped.com/releases/202409301155/frontend/modules/nuxt-language-negotiation/src/runtime/plugins/language.ts";
import graphqlMiddleware_2xI7SMabjg from "/var/www/prod.exped.com/releases/202409301155/frontend/plugins/graphqlMiddleware.ts";
import staticNodes_jws7hzSlX1 from "/var/www/prod.exped.com/releases/202409301155/frontend/modules/vuepal3/src/runtime/plugins/staticNodes.ts";
import drupalUser_HxAWH89yLt from "/var/www/prod.exped.com/releases/202409301155/frontend/plugins/drupalUser.ts";
import index_FC2qyVzm1Q from "/var/www/prod.exped.com/releases/202409301155/frontend/modules/vuepal3/texts/runtime/plugin/index.ts";
import trackSwitchPage_qdAanb2AJL from "/var/www/prod.exped.com/releases/202409301155/frontend/plugins/trackSwitchPage.ts";
export default [
  provideState_MaX9fQr3GV,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  blokkliEditable_kzWzvLoIBF,
  chunk_reload_client_UciE0i6zes,
  language_KdSMap7Fzd,
  graphqlMiddleware_2xI7SMabjg,
  staticNodes_jws7hzSlX1,
  drupalUser_HxAWH89yLt,
  index_FC2qyVzm1Q,
  trackSwitchPage_qdAanb2AJL
]