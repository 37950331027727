import { default as indextpQoBGheX4Meta } from "/var/www/prod.exped.com/releases/202409301155/frontend/pages/[...slug]/index.vue?macro=true";
import { default as indexxDYi1ttKF0Meta } from "/var/www/prod.exped.com/releases/202409301155/frontend/pages/faqs/index.vue?macro=true";
import { default as indexjGvNUn1ILdMeta } from "/var/www/prod.exped.com/releases/202409301155/frontend/pages/groups/index.vue?macro=true";
import { default as indexBxUic5i5DBMeta } from "/var/www/prod.exped.com/releases/202409301155/frontend/pages/media/[id]/index.vue?macro=true";
import { default as indexORHSR1CujZMeta } from "/var/www/prod.exped.com/releases/202409301155/frontend/pages/newsroom/index.vue?macro=true";
import { default as index7GjZ48ivr9Meta } from "/var/www/prod.exped.com/releases/202409301155/frontend/pages/products/[category]/[product]/index.vue?macro=true";
import { default as indexxHWKWyjnQ4Meta } from "/var/www/prod.exped.com/releases/202409301155/frontend/pages/products/[category]/index.vue?macro=true";
import { default as indexVvPtDmFbuaMeta } from "/var/www/prod.exped.com/releases/202409301155/frontend/pages/sitemap/index.vue?macro=true";
export default [
  {
    name: indextpQoBGheX4Meta?.name ?? "slug",
    path: indextpQoBGheX4Meta?.path ?? "/:slug(.*)*",
    meta: indextpQoBGheX4Meta || {},
    alias: indextpQoBGheX4Meta?.alias || [],
    redirect: indextpQoBGheX4Meta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409301155/frontend/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxDYi1ttKF0Meta?.name ?? "faqs",
    path: indexxDYi1ttKF0Meta?.path ?? "/faqs",
    meta: indexxDYi1ttKF0Meta || {},
    alias: indexxDYi1ttKF0Meta?.alias || [],
    redirect: indexxDYi1ttKF0Meta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409301155/frontend/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexjGvNUn1ILdMeta?.name ?? "groups",
    path: indexjGvNUn1ILdMeta?.path ?? "/groups",
    meta: indexjGvNUn1ILdMeta || {},
    alias: indexjGvNUn1ILdMeta?.alias || [],
    redirect: indexjGvNUn1ILdMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409301155/frontend/pages/groups/index.vue").then(m => m.default || m)
  },
  {
    name: indexBxUic5i5DBMeta?.name ?? "media-id",
    path: indexBxUic5i5DBMeta?.path ?? "/media/:id()",
    meta: indexBxUic5i5DBMeta || {},
    alias: indexBxUic5i5DBMeta?.alias || [],
    redirect: indexBxUic5i5DBMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409301155/frontend/pages/media/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexORHSR1CujZMeta?.name ?? "newsroom",
    path: indexORHSR1CujZMeta?.path ?? "/newsroom",
    meta: indexORHSR1CujZMeta || {},
    alias: indexORHSR1CujZMeta?.alias || [],
    redirect: indexORHSR1CujZMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409301155/frontend/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: index7GjZ48ivr9Meta?.name ?? "products-category-product",
    path: index7GjZ48ivr9Meta?.path ?? "/products/:category()/:product()",
    meta: index7GjZ48ivr9Meta || {},
    alias: index7GjZ48ivr9Meta?.alias || [],
    redirect: index7GjZ48ivr9Meta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409301155/frontend/pages/products/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxHWKWyjnQ4Meta?.name ?? "products-category",
    path: indexxHWKWyjnQ4Meta?.path ?? "/products/:category()",
    meta: indexxHWKWyjnQ4Meta || {},
    alias: indexxHWKWyjnQ4Meta?.alias || [],
    redirect: indexxHWKWyjnQ4Meta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409301155/frontend/pages/products/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVvPtDmFbuaMeta?.name ?? "sitemap",
    path: indexVvPtDmFbuaMeta?.path ?? "/sitemap",
    meta: indexVvPtDmFbuaMeta || {},
    alias: indexVvPtDmFbuaMeta?.alias || [],
    redirect: indexVvPtDmFbuaMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409301155/frontend/pages/sitemap/index.vue").then(m => m.default || m)
  }
]