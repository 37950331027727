
import { updateAppConfig } from '#app'
import { defuFn } from '/var/www/prod.exped.com/releases/202409301155/frontend/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "nuxt": {},
  "graphqlMiddleware": {
    "clientCacheEnabled": false,
    "clientCacheMaxSize": 100
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /* #__PURE__ */ defuFn(inlineConfig)
